import algoliasearch from 'algoliasearch/lite';
import {getCookie} from '../utils/cookies';

const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_CLIENT,
  process.env.NEXT_PUBLIC_ALGOLIA_PW
);

const stagingClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_STAGING,
  process.env.NEXT_PUBLIC_ALGOLIA_PW_STAGING
);

const stagingSortByConfig = {
  defaultRefinement: 'Stage_staging_1',
  items: [
    {value: 'Stage_staging_1', label: 'Popular'},
    {value: 'Stage_staging_1_replica_best_selling', label: 'Best Selling'},
    {value: 'Stage_staging_1_replica_newest', label: 'Newest'},
  ],
};

const indexMap = {};

function getIndex(indexName) {
  return indexMap[indexName] || client.initIndex(indexName);
}

async function stageSearch({
  query = '',
  originalPage = 1,
  hitsPerPage = 10,
  indexName = '',
  numericFilters = [],
  isFree,
  facetFilters,
  filters,
  ...otherParams // See algolia config for options
}) {
  const index = getIndex(indexName);

  const isFreeFilter = isFree ? 'is_free=1' : '';
  numericFilters.push('is_published=1');
  if (isFree) {
    numericFilters.push(isFreeFilter);
  }

  // Algolia uses 0-based pagination
  const page = originalPage - 1;

  return index.search(query, {
    page,
    facetFilters,
    numericFilters,
    hitsPerPage,
    filters,
    ...otherParams,
  });
}

async function stageStagingSearch({
  query = '',
  originalPage = 1,
  hitsPerPage = 10,
  indexName = '',
  numericFilters = [],
  isFree,
  facetFilters,
  filters,
  ...otherParams // See algolia config for options
}) {
  const index = stagingClient.initIndex(indexName);

  const isFreeFilter = isFree ? 'is_free=1' : '';
  numericFilters.push('is_published=1');
  if (isFree) {
    numericFilters.push(isFreeFilter);
  }

  // Algolia uses 0-based pagination
  const page = originalPage - 1;

  return index.search(query, {
    page,
    facetFilters,
    numericFilters,
    hitsPerPage,
    filters,
    ...otherParams,
  });
}

function hash({query = '', page = 1, hitsPerPage = 10}) {
  return `${query}-${page}-${hitsPerPage}`;
}

function shouldUseStagingData(router) {
  return (
    router &&
    'query' in router &&
    ('algolia_staging' in router.query || getCookie('algolia_staging')) &&
    process.env.GIT_BRANCH !== 'main'
  );
}

export {
  stageSearch,
  stagingClient,
  hash,
  stagingSortByConfig,
  shouldUseStagingData,
  stageStagingSearch,
};
export default client;
