/**
 * Get the value to show in the URL for a given refinement
 *
 * @param {object} indexState - The state of the index
 * @param {string} paramName - The name of the refinement
 *
 * @returns {string} - The value of the refinement
 */
function getRefinementURLValue(indexState, paramName) {
  return indexState.refinementList?.[paramName]?.join(',');
}

/**
 * Get the state value from the URL param
 *
 * @param {object} routeState - The state of the route
 * @param {string} propName - The name of the routeState property
 *
 * @returns {string} - The value of the refinement
 */
function getRefinementStateValue(routeState, propName) {
  return routeState[propName]?.split(',');
}

export const DEFAULT_INDEX_NAME = 'Stage_production';

/**
 * Get the index name from the UI state
 *
 * @param {object} state - The current uiState
 *
 * @returns {string} - The name of the 1st key of the state
 * @returns {null} - If the key is the default index name
 */
function getIndexNameFromUiState(state) {
  // Stage_production is usually default, so we can ommit it for a cleaner URL
  const index = Object.keys(state)[0];
  return index === DEFAULT_INDEX_NAME ? null : index;
}

/**
 * Get the index name from the route state
 *
 * @param {object} state - The current routeState
 *
 * @returns {string} - The name of the index. Default to 'Stage_production' if not present
 */
function getIndexNameFromRouteState(state) {
  return state.indexName || DEFAULT_INDEX_NAME;
}

/**
 * Map the sortBy value from the URL to the state value
 * @type {Map<string, string>}
 */
const sortByMap = new Map([
  [DEFAULT_INDEX_NAME, 'mixed'],
  ['Stage_production_replica_best_selling', 'best-selling'],
  ['Stage_production_replica_newest', 'newest'],
]);

/**
 * Get the value to show in the URL for a given sortBy value
 *
 * @param {string} sortByValue - The value of the sortBy
 *
 * @returns {string} - The value to show in the URL
 */
function getSortByURLValue(sortByValue) {
  return sortByMap.get(sortByValue);
}

/**
 * Get the state value from the URL param
 *
 * @param {string} sortByValue - The value of the sortBy
 *
 * @returns {string} - The value to add to the uiState
 * @returns {undefined} - If the value is not found
 */
function getSortByStateValue(sortByValue) {
  for (const [key, value] of sortByMap) {
    if (value === sortByValue) {
      return key;
    }
  }
}

/**
 * Get the routeState from the uiState
 *
 * @param {object} uiState - The current uiState
 *
 * @returns {object} - The routeState
 */
export function stateToRoute(uiState, shouldHideIndexName) {
  const state = Object.values(uiState)[0];
  const indexName = shouldHideIndexName === true ? null : getIndexNameFromUiState(uiState);

  const route = {
    page: state.page,
    //
    // Here we would put all the Rails mapping rules:
    //

    // Search term
    search: state.query,

    // Sort by param
    sortby: getSortByURLValue(state.sortBy),

    // Filters (refinements)
    f_types: getRefinementURLValue(state, 'template_type'),
    f_devices: getRefinementURLValue(state, 'device_tags'),
    f_tags: getRefinementURLValue(state, 'stage_tags'),
    f_models: getRefinementURLValue(state, 'model_tags'),
    f_age: getRefinementURLValue(state, 'age_tags'),
    f_ethnicity: getRefinementURLValue(state, 'ethnicity_tags'),
    f_gender: getRefinementURLValue(state, 'gender_tags'),
  };

  indexName && (route.indexName = indexName);
  return route;
}

/**
 * Get the uiState from the routeState
 *
 * @param {object} routeState - The current routeState
 *
 * @returns {object} - The uiState
 */
export function routeToState(routeState) {
  const indexName = getIndexNameFromRouteState(routeState);

  const state = {
    [indexName]: {
      page: routeState.page,
      //
      // Here we would put all the Rails mapping rules:
      //

      // Search term
      query: routeState.search,

      // Sort by param
      sortBy: getSortByStateValue(routeState.sortby),

      // Filters
      refinementList: {
        template_type: getRefinementStateValue(routeState, 'f_types'),
        device_tags: getRefinementStateValue(routeState, 'f_devices'),
        stage_tags: getRefinementStateValue(routeState, 'f_tags'),
        model_tags: getRefinementStateValue(routeState, 'f_models'),
        age_tags: getRefinementStateValue(routeState, 'f_age'),
        ethnicity_tags: getRefinementStateValue(routeState, 'f_ethnicity'),
        gender_tags: getRefinementStateValue(routeState, 'f_gender'),
      },
    },
  };

  return state;
}

/**
 * Merges the params used to query Algolia with additional params in the URL
 *
 * @param {object} qsModule - a module that can parse a query string or stringify an object used by Algolia
 * @param {object} location - an alias to the implementation defined in getLocation. By default, it returns window.location
 * @param {object} routeState - The current routeState
 *
 * @returns url string
 */
export function mergeUrlParams({qsModule, location, routeState}) {
  const {origin, pathname, hash, search} = location;

  // grab current query string and convert to object
  const queryParameters = qsModule.parse(search.slice(1)) || {};

  // current search params
  const searchState = routeState.search || {};

  // if there is no active search in routeState
  if (Object.keys(searchState).length === 0) {
    // remove the search key from queryParameters in case it exist
    delete queryParameters.search;
  }

  // merge the filter params with the current query params
  Object.assign(queryParameters, routeState);

  let queryString = qsModule.stringify(queryParameters);

  if (queryString.length) {
    queryString = `?${queryString}`;
  }

  return `${origin}${pathname}${queryString}${hash}`;
}
