import {useRouter} from 'next/router';
import {niceAssetsCdn, stagePreviewsCdn} from '../lib/buckets-cdn';
import {shouldUseStagingData} from '../lib/algolia/client';

export default function useBucketsCdn() {
  const router = useRouter();
  const useStagingData = shouldUseStagingData(router);

  function createFullUrlHandler({production, staging}) {
    return path => {
      if (!path) {
        return path;
      }

      let baseUrl = production;

      if (useStagingData) {
        baseUrl = staging;
      }

      return joinPaths(baseUrl, path);
    };
  }

  const stageThumbUrl = createFullUrlHandler(stagePreviewsCdn);
  const niceAssetsUrl = createFullUrlHandler(niceAssetsCdn);

  const productionStageThumbUrl = createFullUrlHandler({
    production: stagePreviewsCdn.production,
    staging: stagePreviewsCdn.production, // We want to use production in both cases
  });

  const getStageThumbLoader = stage => {
    const {
      product_thumb_width: largeWidth,
      product_thumb_path: largePath,
      large_thumb_path: smallPath,
    } = stage;

    const imgLoader = ({width}) => {
      let imagePath = smallPath;

      if (width > largeWidth) {
        imagePath = largePath;
      }

      return stageThumbUrl(imagePath);
    };

    return imgLoader;
  };

  return {
    stageThumbUrl,
    productionStageThumbUrl,
    niceAssetsUrl,
    getStageThumbLoader,
  };
}

function joinPaths(...paths) {
  return paths
    .map((path, index) => {
      if (index !== 0) {
        path = path.replace(/^\/+/, '');
      }
      return path.replace(/\/+$/, '');
    })
    .join('/');
}
