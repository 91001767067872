const baseUrl = hostname => `https://${hostname}`;

export const stagePreviewsCdn = {
  production: baseUrl(process.env.NEXT_PUBLIC_PLACEIT_IMG_CDN),
  staging: baseUrl(process.env.NEXT_PUBLIC_PLACEIT_IMG_CDN_STAGING),
};

export const niceAssetsCdn = {
  production: baseUrl(process.env.NEXT_PUBLIC_NICE_ASSETS_CDN),
  staging: baseUrl(process.env.NEXT_PUBLIC_NICE_ASSETS_CDN_STAGING),
};
