import PropTypes from 'prop-types';
import StageRecord from './StageRecord';

const ServerState = PropTypes.shape({
  initialResults: PropTypes.shape({
    requestParams: PropTypes.shape({
      facets: PropTypes.arrayOf(PropTypes.string),
      filters: PropTypes.string,
    }),
    state: PropTypes.shape({
      filters: PropTypes.string,
      index: PropTypes.string,
      hitsPerPage: PropTypes.number,
    }),
    results: PropTypes.arrayOf(StageRecord),
  }),
});

export default ServerState;
