import singletonRouter, {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useState, useEffect, useContext} from 'react';
import {Configure, InstantSearch, InstantSearchSSRProvider} from 'react-instantsearch';
import {createInstantSearchRouterNext} from 'react-instantsearch-router-nextjs';
import {stateToRoute, routeToState, mergeUrlParams} from '../../lib/algolia/url-mapper';
import searchClient, {stagingClient, shouldUseStagingData} from '../../lib/algolia/client';
import 'instantsearch.css/themes/algolia-min.css';
import {UserContext} from '../../context/UserContext';
import {AlgoliaContext} from '../../context/AlgoliaContext';
import ServerState from '../types/ServerState';

export default function InstantSearchProvider({
  children,
  indexName,
  hitsPerPage,
  facetFilters,
  filters,
  query,
  shouldHideIndexName,
}) {
  const {currentUser} = useContext(UserContext);
  const {serverUrl, serverState} = useContext(AlgoliaContext);
  let analyticsOptions = {};
  const router = useRouter();

  const [index, setIndex] = useState(indexName || 'Stage_production');
  const [client, setClient] = useState(searchClient);

  let stage;
  let lastRouteState;
  let routerUpdate;
  let nextUrl;
  let fixTo;

  useEffect(() => {
    if (shouldUseStagingData(router)) {
      setIndex('Stage_staging_1');
      setClient(stagingClient);
    }
    return null;
  }, [router]);

  useEffect(() => {
    if (currentUser?.loggedIn && currentUser?.loggedIn !== 'loading' && currentUser?.user_id) {
      analyticsOptions = {
        clickAnalytics: true,
        userToken: `${currentUser?.user_id}`,
        analyticsTags: "['stageSearch']",
      };
    }
    return null;
  }, []);

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        searchClient={client}
        indexName={index}
        routing={{
          router: createInstantSearchRouterNext({
            singletonRouter,
            serverUrl,
            routerOptions: {
              cleanUrlOnDispose: false,
              createURL: (qsModule, location, routeState) => {
                // save url to fix bug in refinment routing after PDP open/close
                nextUrl = mergeUrlParams(qsModule, location, routeState);
                return nextUrl;
              },
            },
            beforeStart(onUpdate) {
              routerUpdate = event => {
                stage = event.detail.stageId;
                onUpdate(event);
                return false;
              };
              window.removeEventListener('route-or-history-change', routerUpdate);
              window.addEventListener('route-or-history-change', routerUpdate);
            },
            beforeDispose() {
              window.removeEventListener('route-or-history-change', routerUpdate);
            },
          }),
          stateMapping: {
            stateToRoute: searchState => {
              const routeState = stateToRoute(searchState, shouldHideIndexName);
              if (!stage) {
                // save state to use in case PDP opens and we need to maintain grid
                lastRouteState = routeState;
                // fix for location not upadating on first refinement after PDP open/close
                clearTimeout(fixTo);
                fixTo = setTimeout(() => {
                  if (nextUrl && window.location.href !== nextUrl) {
                    // Detect if we have filters and push the correct url
                    const hasFilters = Object.keys(routeState).some(
                      key => routeState[key] !== undefined
                    );
                    if (hasFilters) {
                      router.push(nextUrl);
                    } else {
                      router.push(window.location.href);
                    }
                    nextUrl = undefined;
                  }
                }, 500); // default InstantSearch router history delay is 400ms
              }
              return routeState;
            },
            routeToState: routeState => {
              if (lastRouteState && stage) {
                // PDP is opening, used saved grid state
                routeState = Object.assign(routeState, lastRouteState);
              }
              return routeToState(routeState);
            },
          },
        }}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          hitsPerPage={hitsPerPage}
          facets={['invisible_tags']}
          facetFilters={[facetFilters]}
          filters={filters}
          query={query}
          removeWordsIfNoResults="allOptional"
          {...analyticsOptions}
          clickAnalytics
        />
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
}

InstantSearchProvider.defaultProps = {
  children: null,
  indexName: 'Stage_production',
  hitsPerPage: 60,
  filters: 'is_published=1',
  query: '',
  facetFilters: '',
  shouldHideIndexName: false,
};

InstantSearchProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  indexName: PropTypes.string,
  hitsPerPage: PropTypes.number,
  filters: PropTypes.string,
  query: PropTypes.string,
  facetFilters: PropTypes.string,
  serverState: ServerState.isRequired,
  serverUrl: PropTypes.string.isRequired,
  shouldHideIndexName: PropTypes.bool,
};

export const PlasmicProps = {
  name: 'InstantSearchProvider',
  props: {
    indexName: {
      type: 'string',
      defaultValue: 'Stage_production',
    },
    hitsPerPage: {
      type: 'number',
      defaultValue: 25,
    },
    filters: {
      type: 'string',
      defaultValue: 'is_published=1',
    },
    children: {
      type: 'slot',
    },
    query: {
      type: 'string',
    },
  },
  importPath: './components/Discovery/InstantSearchProvider',
  isDefaultExport: true,
};

export const staticRender = false;
