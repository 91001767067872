import {useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import {
  pdpBreakpoints as pdpSystemBreakpoints,
  breakpoints as systemBreakpoints,
} from '../../../../lib/constants';

const PDP_BREAKPOINTS_TABLE = {
  sm: pdpSystemBreakpoints.sm - 1,
  md: pdpSystemBreakpoints.md - 1,
  lg: pdpSystemBreakpoints.lg - 1,
  xl: pdpSystemBreakpoints.xl - 1,
  '2xl': pdpSystemBreakpoints['2xl'] - 1,
  '3xl': pdpSystemBreakpoints['3xl'] - 1,
};

const BREAKPOINTS_TABLE = {
  sm: systemBreakpoints.md - 1,
  md: systemBreakpoints.lg - 1,
  lg: systemBreakpoints.xl - 1,
  xl: systemBreakpoints['2xl'] - 1,
  '2xl': systemBreakpoints['3xl'] - 1,
};

const getColumnCountTable = (columns, pdpBreakpoints) => {
  const countTable = {
    default: pdpBreakpoints ? 3 : columns['3xl'],
  };
  const breakpoints = pdpBreakpoints ? PDP_BREAKPOINTS_TABLE : BREAKPOINTS_TABLE;
  Object.entries(breakpoints).forEach(([key, value]) => {
    countTable[value] = columns[key];
  });

  return countTable;
};

/**
Generic Grid to show items. The size of the columns in each screen resolution is calculated automatically.
*/
const MasonryGrid = forwardRef(
  ({className, breakpointColumns, pdpBreakpoints, columnClassName, children}) => {
    const columns = useMemo(
      () => getColumnCountTable(breakpointColumns, pdpBreakpoints),
      [breakpointColumns]
    );
    const columnGaps = pdpBreakpoints ? 'gap-[10px] sm:gap-[18px]' : 'gap-4 lg:gap-6';

    return (
      <Masonry
        breakpointCols={columns}
        className={className}
        columnClassName={`flex flex-col ${columnGaps} ${columnClassName}`}
        data-testid="masonry"
      >
        {children}
      </Masonry>
    );
  }
);

export default forwardRef(function MasonryGridRef(props, ref) {
  return (
    <div className="w-full" ref={ref}>
      <MasonryGrid {...props} />
    </div>
  );
});

MasonryGrid.displayName = 'MasonryGrid';

MasonryGrid.defaultProps = {
  className: '',
  columnClassName: 'px-2 lg:px-3',
  breakpointColumns: {
    sm: 2,
    md: 3,
    lg: 3,
    xl: 4,
    '2xl': 5,
    '3xl': 7,
  },
  pdpBreakpoints: false,
};

MasonryGrid.propTypes = {
  /**
   * Elements placed to render grid content
   */
  children: PropTypes.node.isRequired,
  /**
   * The optional CSS classes to be applied on the grid
   */
  className: PropTypes.string,
  /**
   * The optional CSS classes to be applied on each grid column
   */
  columnClassName: PropTypes.string,
  /**
   * Number of columns shown in each resolution
   - sm: < 900px
   - md: 900px - 1024px
   - lg: 1025px - 1280px
   - xl: 1281px - 1536px
   - '2xl': 1537px - 1900px
   - '3xl': \> 1900px
   */
  breakpointColumns: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    '2xl': PropTypes.number,
    '3xl': PropTypes.number,
  }),
  pdpBreakpoints: PropTypes.bool,
};
