import PropTypes from 'prop-types';

const StageRecord = PropTypes.shape({
  /**
   * Stage id, in algolia can be found as ObjectId
   */
  id: PropTypes.number,
  /**
   * URL for the stage's PDP
   */
  stage_link: PropTypes.string,
  /**
   * relative path to the editor page
   */
  editor_link: PropTypes.string,
  /**
   * Algolia tags associated with the stage
   */
  stage_tags: PropTypes.arrayOf(PropTypes.string),
  /**
   * Path of the image shown in the card
   */
  product_thumb: PropTypes.string,
  /**
   * Path of the large_thumb
   */
  large_thumb: PropTypes.string,
  /**
   * Path of the preview image
   */
  preview_image_path: PropTypes.string,
  /**
   * Seo-friendly title
   */
  seo_title: PropTypes.string,
  /**
     * Template type of the card. Each number is related to a template type
      - 13: 'image'
      - 15: 'video'
      - 16: 'bundle'
      - 18: 'expressVideo'
     */
  image_type_id: PropTypes.oneOf([13, 15, 16, 18]),
  /**
   * If this value is true, the Free ribbon is visible
   */
  is_free: PropTypes.bool,
  /**
   * If this stage has been favorited by the logged-in user
   */
  is_favorite: PropTypes.bool,
});

export default StageRecord;
